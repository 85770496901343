export interface Job {
	active: boolean;
	category: string;
	createdAt: string;
	fullDescription: string;
	id: string;
	indeedUrl: string;
	location: string | null;
	shortDescription: string | null;
	statusUpdatedAt: string | null;
	title: string;
}

export interface ContactForm {
	firstName: string;
	lastName: string;
	email: string;
	subject: string;
	mathProblem: number | null;
	message: string;
	phone: string;
	captchaResponse: string;
}

export interface ArrivalForm {
	firstName: string;
	lastName: string;
	parkingSpotNumber: number | null;
	paymentMethod: 'Cash' | 'Debit' | 'Credit';
}

export interface JobWithPageMetadata extends Job {
	pageMetadata: PageMetadata;
}

export interface ProductMetadata {
	createdAt: string | null;
	excludedFromFirstTimeDiscount: boolean;
	dealExplainer: string | null;
	dealTag: string | null;
	description: string | null;
	effects: string[] | null;
	featured: boolean;
	featuredOrder: number | null;
	id: string;
	likes: number | null;
	newTag: boolean | null;
	newTagExpiration: string | null;
	potencyCbd: string | null;
	potencyThc: string | null;
	primaryCategoryId: string | null;
	productCategories: ProductCategory[] | null;
	productImages: Image[] | null;
	reviews?: Review[];
	seoDescription: string | null;
	seoOgTitle: string | null;
	seoOgUrl: string | null;
	seoTitle: string | null;
	showLineage: boolean;
	slug: string;
	standardShopOrder: number | null;
	terpeneCannabinoidList: string | null;
	terpeneCannabinoidPercentages: string | null;
	totalCannabinoids: string | null;
}

export interface Review {
	approved: boolean;
	body: string | null;
	createdAt: string;
	email?: string;
	id: string;
	ipAddress?: string | null;
	likes: number;
	name: string | null;
	productMetadataId: string | null;
	productName: string | null;
	rating: number;
	response: string | null;
	title: string;
	productMetadata?: ProductMetadata;
}

export interface PageMetadata {
	brandId: number | null;
	createdAt: string;
	id: string;
	jobId: string | null;
	robots: string[] | null;
	seoDescription: string;
	seoOgImage: string | null;
	seoOgTitle: string;
	seoOgUrl: string;
	seoTitle: string;
	slug: string;
}

export interface ProductCategory {
	createdAt?: string | null;
	id: string;
	name: string;
}

export interface PricingTier {
	createdAt: string | null;
	id: number;
	name: string;
}

export interface PricingTierRule {
	createdAt: string | null;
	endWeightGrams: number;
	id: string;
	pricePerGram: number;
	pricingTierId: number;
	startWeightGrams: number;
}

export interface PricingTierWithRules extends PricingTier {
	pricingTierRules: PricingTierRule[];
}

export interface Strain {
	createdAt: string | null;
	dominateTerpene?: Terpene | null;
	id: number;
	lean: string;
	leanBarPercentage: number | null;
	lineage: string;
	name: string;
}

export interface Image {
	altText: string | null;
	createdAt: string;
	description: string | null;
	fileName: string;
	fileSize: string | null;
	fileType: string;
	includeInWholesaleLibrary: boolean;
	id: string;
	index?: number;
	title: string;
	uploadedBy: string | null;
	url: string;
	urlThumbnail: string | null;
	tags?: Tag[];
}

export interface ImageWithImageRelations extends Image {
	imageRelations: {
		index: number;
		link?: string | null;
	}[];
}

export interface BrandQandA {
	id: number;
	question: string;
	answer: object;
}

export interface Brand {
	id: number;
	createdAt: string;
	name: string;
	h1: string | null;
	h2: string | null;
	p1: object | null;
	p2: object | null;
	body: string | null;
	faq: BrandQandA[] | null;
	reviewsSectionHeading: string | null;
	pageIsVisible: boolean;
}

export interface BrandWithMetadata extends Brand {
	pageMetadata: PageMetadata;
	images: {
		imageId: string;
		isMobile: boolean;
		image: Pick<Image, 'altText' | 'description' | 'url'>;
	}[];
	structuredData: string;
}

export interface ActiveBrandLink {
	name: string;
	pageMetadata: { slug: string };
}

export interface Product {
	availableOnline: boolean | null;
	brand: Brand | null;
	brandId: number | null;
	createdAt: string;
	dutchieId: number;
	groupedProductData?: GroupedProductData[];
	id: number;
	internalName: string | null;
	metadataId: string;
	name: string;
	price: number | null;
	pricingTierId: number | null;
	pricingTiers: PricingTier | null;
	productGrams: number | null;
	productMetadata: ProductMetadata | null;
	productInventory: ProductInventory[];
	size: string | null;
	staffPick: boolean | null;
	strainId: number | null;
	strains: Strain | null;
	tags?: { id: number }[];
	taxCategories: string[] | null;
}

interface PreImageSortingProductMetata extends Omit<ProductMetadata, 'productImages'> {
	productImages: { index: number; image: Image }[] | null;
}

export type ProductPreImageSorting = Omit<Product, 'productMetadata'> & {
	productMetadata: PreImageSortingProductMetata | null;
};

export interface GroupedProductData {
	id: number;
	price: number;
	size: string;
	productGrams?: number | null;
	inventory: ProductInventory[];
}

export interface ProductInventory {
	dutchieLocationId: number;
	dutchieProductId: number;
	quantity: number;
}

export interface GlobalSettings {
	arrivalFormRecipients: string[] | null;
	contactFormRecipients: string[] | null;
	created_at: string | null;
	id: string;
	reviewSubmissionRecipients: string[] | null;
}

export interface StoreSettings {
	arrivalFormRecipients: string[] | null;
	contactFormRecipients: string[] | null;
	id: number;
}

export interface Tag {
	id: string;
	name: string;
}

export interface ProductVariantOptions {
	id: number;
	price: number;
	/**
	 * Used for price tier based products.
	 * This calculates how much it would cost if the price tier discount was not applied.
	 * Example: Add a 1/4oz of 'Good Weed' to the cart
	 * price: 45, priceNonDicounted: 50 (because the base tier is one 1/8th which costs $25)
	 */
	priceNonDiscounted: number | null;
	size: string;
	grams: number | null;
	productQuantityMultiplier: number | null;
}

export interface CartItem extends ProductVariantOptions {
	quantity: number;
	tagIds?: number[];
	pricingTierId: number | null;
	isConcentrate: boolean;
	isGroupedProduct: boolean;
	hasNonStackableDiscount?: boolean;
	originalPrice?: number; // used to show discounted price versus pre-discount
	categories: string[] | null;
	discountTitle?: string;
}

export interface Customer {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	birthday: string;
}

export interface FormattedCartData {
	productId: string;
	quantity: number;
}

export interface BundleBuilderPackSize {
	grams: number;
	pricePerGram: number;
	size: string;
}

export interface BundleBuilderItem extends CartItem {
	name: string;
}

export interface DutchieClientAuthorizationLocations {
	sandbox: string;
	monroe: string;
}

export interface RatingIcon {
	name: string;
	url: string;
	locationId: string;
}

export interface Theme {
	id?: string;
	footerColor: string;
	headerAccentColor: string;
	headerColor: string;
	noticeBarActive: boolean;
	noticeBarMessage: string | null;
	noticeBarType: 'Announcement' | 'Notice' | 'Urgent';
}

export interface Notification {
	id: number;
	type: string;
	message: string;
}

export interface ImageSliderTab {
	id: string;
	desktop: ImageWithImageRelations;
	mobile: ImageWithImageRelations;
}

export interface SyncStats {
	createdAt: string;
	errorMessage: string | null;
	id: string;
	isSyncing: boolean;
	lastSyncDuration: number | null;
	lastSyncSuccess: boolean | null;
	lastSyncTime: string | null;
}

export type HeadingSortState = 'asc' | 'desc' | 'inactive';

export interface FilterOptionsCount {
	[key: string]: number;
}

export type Terpene =
	| 'Myrcene'
	| 'α-Pinene'
	| 'ß-Pinene'
	| 'Limonene'
	| 'Caryophyllene'
	| 'Linalool'
	| 'Terpinolene'
	| 'Humulene'
	| 'Ocimene'
	| 'α-Terpineol'
	| 'γ-Terpineol'
	| 'Nerolidol'
	| 'Phytol'
	| 'Camphene'
	| 'Geraniol'
	| 'Eucalyptol'
	| 'Camphor'
	| 'Valencene'
	| 'Geranyl Acetate'
	| 'α-Cedrene'
	| 'α-Terpinene'
	| 'γ-Terpinene'
	| 'Fenchol'
	| 'Caryophyllene oxide'
	| 'Farnesene'
	| 'Borneol'
	| 'Isoborneol'
	| 'α-Bisabolol'
	| 'Fenchone'
	| 'Cedrol'
	| 'Sabinene'
	| 'Sabinene Hydrate'
	| 'Guaiol'
	| 'Pulegone'
	| 'Isopulegol'
	| 'Nerol';

export interface PreOrderSummary {
	[key: string]: {
		size: string;
		quantity: number;
	};
}

export interface PreOrder {
	createdAt: string;
	discount: number;
	id: number;
	preDiscountSubtotal: number;
	subtotal: number;
	summary: PreOrderSummary[];
	taxes: number;
	total: number;
}

export interface ReportingPreOrderSums {
	orders: number;
	productsSold: number;
	productsSummary: {
		size: string;
		productId: string; // maybe cast to number in db
		totalQuantity: number;
		subtotal: number;
		productName: string;
	}[];
	totalSubtotal: number;
	totalDiscounts: number;
	totalTaxes: number;
	totalFinal: number;
}

export interface ReportingPreOrderWeeklySums
	extends Pick<
		ReportingPreOrderSums,
		'orders' | 'productsSold' | 'totalDiscounts' | 'totalFinal' | 'totalTaxes'
	> {
	week: string;
}

export interface ReportingPreOrderDailySums extends ReportingPreOrderSums {
	orderDate: string;
}

export interface ReportingPreOrderHourlySums extends ReportingPreOrderSums {
	orderHour: string;
}

export type DateRangeOption = '1D' | '1W' | '1M' | '3M' | '1Y' | 'MTD' | 'YTD' | 'All';

export interface ReportingCustomerPurchaseGroup {
	orderGroupLabel: string;
	customerCount: number;
	totalOrders: number;
}

export interface ForecastProduct {
	productId: number;
	name: string;
	size?: string | null;
	categories?: (string | undefined)[];
	inventory: number;
	unitsSold: number;
	avgDailyUnitsSold: number;
	daysUntilOutOfStock: number | null;
}

export interface ForecastCategory {
	name: string;
	inventory: number;
	unitsSold: number;
	avgDailyUnitsSold: number;
	daysUntilOutOfStock: number | null;
}

//
//
//
//
// Supabase Gotrue
//
// I wish I could import this directly but I don't know how to convert snake types into camelCase
// TODO - Figure out how to convert type interfaces

interface UserIdentity {
	id: string;
	userId: string;
	identityData?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	provider: string;
	createdAt?: string;
	lastSignInAt?: string;
	updatedAt?: string;
}

interface UserAppMetadata {
	provider?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

interface UserMetadata {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

interface Factor {
	/** ID of the factor. */
	id: string;

	/** Friendly name of the factor, useful to disambiguate between multiple factors. */
	friendlyName?: string;

	/**
	 * Type of factor. Only `totp` supported with this version but may change in
	 * future versions.
	 */
	factorType: 'totp' | string;

	/** Factor's status. */
	status: 'verified' | 'unverified';

	createdAt: string;
	updatedAt: string;
}

export interface User {
	id: string;
	appMetadata: UserAppMetadata;
	userMetadata: UserMetadata;
	aud: string;
	confirmationSentAt?: string;
	recoverySentAt?: string;
	emailChangeSentAt?: string;
	newEmail?: string;
	newPhone?: string;
	invitedAt?: string;
	actionLink?: string;
	email?: string;
	phone?: string;
	createdAt: string;
	confirmedAt?: string;
	emailConfirmedAt?: string;
	phoneConfirmedAt?: string;
	lastSignInAt?: string;
	role?: string;
	updatedAt?: string;
	identities?: UserIdentity[];
	factors?: Factor[];
}

interface Discount {
	amount: number;
	code: string | null;
	createdAt: string;
	firstTimeCustomerOnly: boolean;
	id: number;
	includeNonCannabis: boolean;
	isActive: boolean;
	maxItems: number | null;
	maxUsage: number | null;
	method: string;
	minItems: number;
	name: string;
	stackable: boolean;
	type: string;
	validFrom: string;
	validTo: string;
}

export interface DiscountTag {
	tagId: number;
	isExclusion: boolean;
	discount: Discount;
}

export type UserRole = 'staff' | 'manager' | 'admin' | 'customer' | 'receptionist' | 'forecasts';
export const USER_ROLES: UserRole[] = [
	'staff',
	'manager',
	'admin',
	'customer',
	'receptionist',
	'forecasts',
];
